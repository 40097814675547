import React from 'react'
import FirebaseAuthProvider, { AuthRole } from '@flock/utils/src/auth/auth'

import ConfiguredApolloProvider from './apollo'

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }: any) => (
  <FirebaseAuthProvider authorizedRoles={[AuthRole.ADMIN]}>
    <ConfiguredApolloProvider>{element}</ConfiguredApolloProvider>
  </FirebaseAuthProvider>
)
